.upcoming-calendar .react-calendar {
    width: 100%;
    height: 100%;
    max-height: 100% !important;
    max-width: 100% !important;
    background: transparent !important;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.0em;
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
    .upcoming-calendar .react-calendar {
        /* width: 350px; */
    }
}

.upcoming-calendar .react-calendar--doubleView {
    width: 700px !important;
}

.upcoming-calendar .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.upcoming-calendar .react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.upcoming-calendar .react-calendar,
.upcoming-calendar .react-calendar *,
.upcoming-calendar .react-calendar *:before,
.upcoming-calendar .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.upcoming-calendar .react-calendar button {
    margin: 0;
    border: 0 !important;
    outline: none !important;
}

.upcoming-calendar .react-calendar button:enabled:hover {
    cursor: pointer;
}

.upcoming-calendar .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.upcoming-calendar .react-calendar__navigation button {
    min-width: 44px;
    background: transparent !important;
    /* color: #212934; */
}

/* .upcoming-calendar .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
} */


.upcoming-calendar abbr:where([title]) {
    text-decoration: none !important;
}

.upcoming-calendar .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: semi-bold;
    font-size: 14px;
    color: #8F9499 !important;
    background: #F5F8FA !important;
}

.upcoming-calendar .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.upcoming-calendar .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: medium;
    border-radius: 50% !important;
}

.upcoming-calendar .react-calendar__month-view__days__day--neighboringMonth {
    color: #b4b2b2 !important;
}

.upcoming-calendar .react-calendar__year-view .react-calendar__tile,
.upcoming-calendar .react-calendar__decade-view .react-calendar__tile,
.upcoming-calendar .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.upcoming-calendar .react-calendar__tile {
    padding: 0 !important;
    height: 42px;
    width: 20px;
    max-width: 100% !important;
    background: none;
    text-align: center;
    color: #212934;

}

.upcoming-calendar .react-calendar__tile.react-calendar__month-view__days__day {
    border-radius: 50% !important;

}

.upcoming-calendar .react-calendar__tile:disabled {
    /* background-color: #f0f0f0; */
    color: gray !important;
    opacity: 0.2;
}

.upcoming-calendar .react-calendar__tile:enabled:hover,
.upcoming-calendar .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6 !important;
    border-radius: 10% !important;
    /* width: 50%;
    height: 50%; */
}

.upcoming-calendar .react-calendar__tile--now {
    /* background: #6145D0 !important; */
    color: #177AE5 !important;
}

.upcoming-calendar .react-calendar__tile--active {
    /* background: #6145D0; */
    color: #177AE5 !important;
    /* border-radius: 70%; */
}

.upcoming-calendar .react-calendar__tile--active.highlights {
    background-color: #6145D0 !important;
    color: white !important;
}


.upcoming-calendar .react-calendar__tile--active:enabled:hover,
.upcoming-calendar .react-calendar__tile--active:enabled:focus {
    background: #177AE5 !important;
    color: white !important;
}

.upcoming-calendar .react-calendar--selectRange .react-calendar__tile--hover {
    /* background-color: #6145D0; */
}

.upcoming-calendar .highlights {
    /* color: #6145D0; */
}

/* 
.upcoming-calendar .react-calendar__tile .react-calendar__tile--active .react-calendar__tile--range .react-calendar__tile--rangeStart .react-calendar__tile--rangeEnd .react-calendar__tile--rangeBothEnds .react-calendar__month-view__days__day .highlights {
    color: white !important;
    background-color: red !important;
} */

/* .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
} */

/* .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
} */


/* .react-calendar__tile--hasActive {
    background: #006666;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #006666;
} */