@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

.Toastify__toast-theme--light {
  font-size: 14px;
  color: #9ca3af;
}



/* Hide scrollbar for Chrome, Safari and Opera */
.ril-widget_scrollbar * ::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ril-widget_scrollbar * {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}